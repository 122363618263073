@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;400;600;700;900&display=swap');
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    font-weight: 400;
    vertical-align: baseline;
    -webkit-text-size-adjust: 100%;
    font-family: 'Titillium Web', sans-serif;
    color: inherit;
    text-decoration: inherit;
}
b {
    font-weight: 700;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
html {
    line-height: 1;
}
body {
    background-color: black;
    min-height: 100vh;
    height: 1px;
}
body, .light {
    background-color: white;
}
body, .dark {
    background-color: black;
}

#root {
    background: #74ebd5;
    background: -webkit-linear-gradient(to right, #ACB6E5, #74ebd5);
    background: linear-gradient(to bottom right, #5c79ff, #74ebd5);
    min-height: 100%;
    background: white;
}

:root{
    scroll-padding: 100px;
    scroll-behavior: smooth;
}

ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

:root {
    --color-feature-primary: #009a76;
    --color-feature-primary-lighten: #83bbae;
    --color-feature-primary-negative: #d1007a;
}

.hideScroll::-webkit-scrollbar { display: none;}

.scrollGradientHorizontal {
    width: 100%;
    -webkit-mask-image: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 10%);
    padding-left: 4px;
}
.scrollGradientVertical {
    -webkit-mask-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 20px);
    padding: 8px 0;
}

h1 {
    font-weight: 700;
    font-size: 20pt;
    margin: 16px;
}

h2 {
    font-weight: 700;
    font-size: 15pt;
    margin: 6pt 0;
}
.card_title {
    color: white;
    text-shadow: 0 0 6px black;
}
.card_subtitle {
    text-align: center;
    margin: 0px 0px -16px;
    width: 100%;
    padding: 48px 16px 16px;
    border-radius: 0px 0px 16px 16px;
}

h3 {
    /* font-weight: 700; */
    font-size: 14pt;
    margin: 6pt;
    margin: 3pt 6pt 6pt 0;
}

.padding8px {
    padding: 8px;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}
.event-notification {
    top: 0;
    width: 100%;
    text-align: center;
    background: aquamarine;
    position: relative;
    font-weight: 600;
}

.setting-label {
    width: 90px;
    font-size: 80%;
    margin-left: 3px;
    opacity: 0.7;
    white-space: nowrap;
}
.setting-input {
    background:none;
    border:none;
    font-size:inherit;
    flex-grow: 1;
    font-family:inherit;
    font-weight:700;
    margin:0;
    padding: 2px;
    border: 1px solid #0000;
    border-radius: 4px;
    transition: border .2s;
    box-sizing: border-box;
    line-height: 26px;
    height: 26px;
}
.setting-input:focus {
    border: 1px solid black
}

.swipeable-list-item {
    position: relative;
}

.swipeable-list-item__content-left {
    position: absolute;
    height: 100%;
    width: calc(100% - 48px);
    background: #FF0084;
    right: 0;
    text-align: right;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    padding: 0 24px;
    opacity: 0;
    margin: 0;
}
.swipeable-list-item__content-left--return {
    transition: opacity 0.2s ease
}
.swipeable-list-item__content-right {
    position: absolute;
    height: 100%;
    width: calc(100% - 48px);
    background: #00F07C;
    display: flex;
    align-items: center;
    padding: 0 24px;
    opacity: 0;
    margin: 0;
}
.swipeable-list-item__content-right--return {
    transition: opacity 0.2s ease
}
.swipeable-list-item__content--return {
    transition: transform 0.2s ease
}

.full-width-hoverable {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 40px;
    padding-left: 16px;
}

.full-width-hoverable:hover {
    background-color: rgba(0, 0, 0, 0.04)
}

.qrcode-overlay {
    position: fixed;
    width: 100%;
    max-width: 500px;
    max-height: 550px;
    padding: 30px;
    height: 100%;
    box-sizing: border-box;
    top: 50%;
    transform: translate(-50%,-50%);
    left: 50%;
    background: white;
    z-index: 1500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
    border-radius: 24px;
}

.summary-background {
    width: 100%;
    max-width: 600px;
    height: 48px;
    display: block;
    margin: auto;
    position: fixed;
    text-align: left;
    z-index: 1;
    background: white;
    transition: opacity 0.1s ease;
    box-shadow: 0px 10px 10px -10px #000000d4;
}

::-webkit-scrollbar {
    display: none;
}

.summary-text {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 600px;
    position: fixed;
    z-index: 1;
}

.summary-headline {
    text-align: left;
    display: inline-block;
    position: relative;
    transform-origin: 0px 50%;
}

.summary-headline h1{
    margin: 4px;
    margin-left: 16px;
    font-size: 30pt;
    display: inline-block;
    position: relative;
}
.summary-headline h2{
    font-size: 20pt;
    line-height: 30pt;
    margin-top: 8px;
    margin-bottom: 8px;
    display: inline-block;
    position: absolute;
    width: 1000px;
    margin-Left: calc((min(300px, 50vw) / 0.6) - 100%);
}

.focus {
    opacity: 1;
    animation: glowToHide 3s linear;
    animation-delay: 0s;
    animation-fill-mode: forwards;
}

#SWIPE_EXPENSES {
    animation: SWIPE_EXPENSES 8s infinite;
    animation-delay: 1s;
}

/*TODO delete this one Safari fix material button text not changing color on iOS*/
.MuiButton-root {
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important
}

@keyframes SWIPE_EXPENSES {
    0% {
        transform: translateX(0%);
    }
    10% {
        transform: translateX(-20%);
    }
    30% {
        transform: translateX(20%);
    }
    40% {
        transform: translateX(0%);
    }
}

@keyframes glowToHide {
    0% {
        opacity: 1;
    }
    10% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    30% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    60% {
        opacity: 1;
    }
    70% {
        opacity: 0;
    }
    80% {
        opacity: 1;
    }
    90% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}



.loader {
    height: 100%;
    width: 100%;
    background-color: rgb(0, 0, 0);
    position: fixed;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    padding: 16px;
    box-sizing: border-box;
    text-align: center;
}

.frostedLoader {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(1px);
    position: fixed;
    z-index: 1610;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    padding: 16px;
    box-sizing: border-box;
    text-align: center;
}

.loadingLogo {
    background: linear-gradient(to right, #00F07C,#006BFF,#8900FF,#FF0084,#8900FF,#006BFF,#00F07C,#006BFF,#8900FF,#FF0084);
    -webkit-mask-image: url("logo_black_1080x1080.png");
    mask-image: url("logo_black_1080x1080.png");
    -webkit-mask-size: contain;
    mask-size: contain;
    height: 200px;
    width: 200px;
    background-size: 300% 100%;
    background-repeat: repeat-x;
    -webkit-animation: rainbowSlide 3s linear infinite;
    -moz-animation: rainbowSlide 3s linear infinite;
    animation: rainbowSlide 3s linear infinite; 
}

.loadingText {
    animation: 1s fadeInSlideUp linear;
    animation-fill-mode: forwards;
    visibility: hidden;
    font-size: 30px;
    font-weight: 600;
    padding-top: 30px;
    position: relative;
    top: 60px;
}
.notFast {
    animation-delay: 1s;
}
.takingAWhile {
    animation-delay: 6s;
}
.checkYourInternet {
    animation-delay: 12s;
}
.allIsLost {
    animation-delay: 20s;
}

.headerLogo {
    background: linear-gradient(to right, #00F07C,#006BFF,#8900FF,#FF0084,#8900FF,#006BFF,#00F07C,#006BFF,#8900FF,#FF0084);
    -webkit-mask-image: url("logo_black_1080x1080.png");
    mask-image: url("logo_black_1080x1080.png");
    -webkit-mask-size: contain;
    mask-size: contain;
    height: 32px;
    width: 32px;
    margin: 8px;
    background-size: 300% 100%;
    background-repeat: repeat-x;
    cursor: pointer;
}
.loading {
    -webkit-animation: rainbowSlide 3s linear infinite;
    -moz-animation: rainbowSlide 3s linear infinite;
    animation: rainbowSlide 3s linear infinite; 
}
.loading_fast {
    -webkit-animation: rainbowSlide 1s linear infinite;
    -moz-animation: rainbowSlide 1s linear infinite;
    animation: rainbowSlide 1s linear infinite; 
}
.headerLogo.back {
    -webkit-mask-image: url("back.png");
    mask-image: url("back.png");
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
}

.modal .section {
    /* min-height: 260px; */
    margin: 0;
    border-radius: 32px 32px 16px 16px;
}

.modal {
    background: #000000dd;
    display: flex;
    align-items: flex-end;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 1070;
    position: fixed;
}

.group_change_title {
    width: 30%;
    text-align: center;
    z-index: 1;
}

.section {
    border-radius: 16px;
    position:relative;
    background-color: rgb(241, 245, 255);
    margin: 0 4px;
    padding: 8px;
    box-sizing: border-box;
    padding-bottom: 0;
    max-width: 600px;
    width: 100%;
    border-bottom: 16px solid rgb(241, 245, 255);
    box-shadow: rgb(0 0 0 / 5%) 0px 0px 8px 2px;
}
.section:after {
    content:'';
    position:absolute;
    width: 100%;
    height: 16px;
    background: #23ffe7;
    background: linear-gradient(to right, #00F07C,#006BFF,#8900FF,#FF0084,#8900FF,#006BFF,#00F07C,#006BFF,#8900FF,#FF0084);
    /* background:  linear-gradient(left,#1DD0BC,#188DD7,#873ABA,#FF0CD0,#873ABA,#188DD7,#1DD0BC,#188DD7,#873ABA,#FF0CD0);*/
    /* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1dd0bc', endColorstr='#43009c' ,GradientType=1 ); */
    background-size: 300% 8px;
    background-repeat: repeat-x;
    border-radius: 0 0 16px 16px;
    bottom:-16px;
    left:0;
    -webkit-animation: rainbowSlide 5s linear infinite;
    -moz-animation: rainbowSlide 5s linear infinite;
    animation: rainbowSlide 5s linear infinite; 
}

.subSection {
    background-color: rgb(224, 233, 255);
    margin: 8px 4px;
    border-radius: 4px;
}

.nameChip {
    border-radius: 24px;
    border: none;
    font-size: 18px;
    padding: 0px 12px;
    cursor: pointer;
    display: inline-flex;
    height: 32px;
    align-items: center;
    position: relative;
}
.placeholder:before {
    content: "PH";
    background: black;
    width: 24px;
    height: 24px;
    align-self: center;
    line-height: 24px;
    text-align: center;
    position: relative;
    border-radius: 50%;
    font-size: 12px;
    font-weight: 500;
    color: white;
    transform: translateX(-8px);
}
/* .dark .placeholder:before {
    background: white;
} */

.tall {
    font-size: 20px;
    padding: 0px 0px 0px 16px;
    height: 48px;
    line-height: 20px;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.modal-tab {
    margin: 0 16px;
}

.animated-fadeOut {
    animation: 0.5s fadeOut linear;
    animation-delay: 2s;
    animation-fill-mode: forwards;
}

@-webkit-keyframes rainbowSlide {
    0%{background-position:0% 100%}
    100%{background-position:100% 100%}
}
@-moz-keyframes rainbowSlide {
    0%{background-position:0% 100%}
    100%{background-position:100% 100%}
}
@keyframes rainbowSlide {
    0%{background-position:0% 100%}
    100%{background-position:100% 100%}
}

@keyframes fadeInSlideUp {
    0% {
        opacity: 0;
    }
    100% {
        visibility: visible;
        opacity: 1;
        top: 0px;
    }
}

@keyframes fadeOut {
    0% {
        visibility: visible;
        opacity: 1;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

  /* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


@keyframes qr_animation {
    0% { x1: 0; y1: 0; x2: 1; y2: 1; }
    100% { x1: 1; y1: 1; x2: 2; y2: 2; }
}

.qr-gradient-animated {
    animation: qr_animation 4s infinite linear;
}